function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable @typescript-eslint/naming-convention */

import { validateReturnUrl } from '@uipath/auth-core';
/**
 * A function to get the returnTo back after returning from redirect
 *
 * Note: by default state should be present, but it can be undefined
 * if it is modified in the `AuthGuard` params
 * @param user
 * @returns `state`
 */
export function getCallbackState(user) {
  var state = user === null || user === void 0 ? void 0 : user.state;
  if (!validateReturnUrl(state === null || state === void 0 ? void 0 : state.returnTo)) {
    return _objectSpread(_objectSpread({}, state), {}, {
      returnTo: '/'
    });
  }
  return state;
}

/**
 * Provide this function to `UiPathAuthProvider` if no custom callback logic is needed.
 * Otherwise, use `getCallbackState` to get the `returnTo` and redirect to that route
 * on the callback through your router.
 *
 * Note: this function takes `navigate` as a param to avoid adding `react-router` as a dependency
 * @param user
 * @param navigate `router.navigate` for react router v6, `history.push` otherwise
 */
export function onCallback(user, navigate) {
  var returnTo = getCallbackState(user).returnTo;
  navigate(returnTo);
}